import React from 'react'

import Layout from '../components/Layout'
import SEO from '../components/seo'
import Error from '../components/Error'

const NotFoundPage = () => (
    <Layout>
        <SEO title="404: Not found" />

        <Error />
    </Layout>
)

export default NotFoundPage
