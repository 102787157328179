import React from 'react'
import cx from 'classnames'
import is from 'react-jss'

import style from './style'

const Error = ({
    classes,
}) => {
    return (
        <div className={classes.error}>
            <h1 className={classes.title}>This page can't be found</h1>
            <div className={classes.content}>
                <p>Please use the menu above to find what you are looking for.</p>
            </div>
        </div>
    )
}

export default is(style)(Error)
