export default ({
    colors,
    spaces,
    breakpoints,
}) => ({
    key: { name: 'Error' },

    error: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        minHeight: '100vh',
        width: '100%',
        padding: [0, spaces[3]],
        position: 'relative',
        textAlign: 'center',
        backgroundColor: colors.tertiary,
        color: colors.white,

        '&::before, &::after': {
            display: 'block',
            position: 'absolute',
            content: '""',
        },

        '&::before': {
            width: 200,
            height: 200,
            top: '20%',
            right: -spaces[4],
            border: `3px solid ${colors.secondary}`,
            borderRadius: '100%',
            zIndex: 0,

            [breakpoints.md]: {
                width: 300,
                height: 300,
            },
        },

        '&::after': {
            width: 3,
            height: 240,
            position: 'absolute',
            bottom: '10%',
            left: '20%',
            transform: 'rotate(-36deg)',
            backgroundColor: colors.primary,
            zIndex: 0,

            [breakpoints.md]: {
                height: 300,
            },
        },

        [breakpoints.lg]: {
            padding: 0,
        },
    },

    title: {
        maxWidth: 500,
        marginBottom: spaces[2],
        position: 'relative',
        zIndex: 1,
    },

    content: {
        maxWidth: 500,
        position: 'relative',
        zIndex: 1,
    },
})
